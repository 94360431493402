// store.js
import { createStore } from 'vuex';
import api from '@/axios'; // обновленный импорт для использования настроек
import { format } from 'date-fns';

const store = createStore({
  state: {
    departments: [],
    selectedDepartments: [],
    employeesByDepartment: {},
    dateRange: [],
    employeesMetrics: {},
    loadingStatus: {},
    selectedPeriodType: 'day',
    isMetricsLoading: false,
  },
  mutations: {
    SET_EMPLOYEES_METRICS(state, { periodType, metrics }) {
      if (!state.employeesMetrics[periodType]) {
        state.employeesMetrics[periodType] = {};
      }
      metrics.forEach(metric => {
        const employeeId = String(metric.employee_id);
        state.employeesMetrics[periodType][employeeId] = { ...metric };
      });
    },
    SET_LOADING_STATUS(state, { periodType, employeeId, status }) {
      if (!state.loadingStatus[periodType]) {
        state.loadingStatus[periodType] = {};
      }
      employeeId = String(employeeId);
      state.loadingStatus[periodType][employeeId] = status;
    },
    UPDATE_METRICS(state, { periodType, metrics }) {
      const employeeId = String(metrics.employee_id);
      if (!state.employeesMetrics[periodType]) {
        state.employeesMetrics[periodType] = {};
      }
      state.employeesMetrics[periodType][employeeId] = metrics;
    },
    setSelectedDepartments(state, departmentIds) {
      state.selectedDepartments = departmentIds.map(String);
    },
    setEmployeesByDepartment(state, { departmentId, employees }) {
      departmentId = String(departmentId);
      state.employeesByDepartment = {
        ...state.employeesByDepartment,
        [departmentId]: employees,
      };
    },
    setDateRange(state, dateRange) {
      state.dateRange = dateRange;
    },
    setDepartmentsWithEmployees(state, departments) {
      state.departments = departments.map(department => ({
        ...department,
        id: String(department.id),
      }));
      state.employeesByDepartment = {};
      state.departments.forEach(department => {
        if (!department.employees || !Array.isArray(department.employees)) {
          console.warn(`Department ${department.id} has no employees or employees is not an array`, department);
          state.employeesByDepartment[department.id] = [];
          return;
        }
        const formattedEmployees = department.employees.map(employee => {
          const fileName = employee.file_name;
          let photoPath = `${import.meta.env.VITE_API_BASE_URL}/user_photo/user_40083_circle.png`;
          if (fileName) {
            photoPath = `${import.meta.env.VITE_API_BASE_URL}/user_photo/${fileName}`;
          }
          return {
            id: String(employee.id),
            name: `${employee.name} ${employee.last_name}`,
            position: employee.work_position,
            tag: '',
            photo: photoPath,
          };
        });
        state.employeesByDepartment[department.id] = formattedEmployees;
      });
    },
    setSelectedPeriodType(state, periodType) {
      state.selectedPeriodType = periodType;
    },
    CLEAR_EMPLOYEES_METRICS(state) {
      state.employeesMetrics = {};
      state.loadingStatus = {};
    },
    SET_METRICS_LOADING(state, status) {
      state.isMetricsLoading = status;
    },
  },
  actions: {
    async fetchDepartmentsWithEmployees({ commit }) {
      try {
        const response = await api.get('/departments-with-employees');
        const departments = response.data;
        console.log('Received departments:', departments);

        departments.forEach(department => {
          if (!department.employees || !Array.isArray(department.employees)) {
            console.warn(`Department ${department.id} does not have employees or employees is not an array`, department);
          }
        });

        commit('setDepartmentsWithEmployees', departments);
      } catch (error) {
        console.error('Error fetching departments with employees:', error);
      }
    },
    async fetchMetrics({ commit, state }, { employeeIds, periodType }) {
      if (state.employeesMetrics[periodType] && Object.keys(state.employeesMetrics[periodType]).length > 0) {
        console.log(`Metrics for period ${periodType} are already loaded.`);
        return;
      }

      commit('SET_METRICS_LOADING', true);

      const params = {
        'employee_ids[]': employeeIds.map(String),
      };

      if (periodType === 'custom') {
        const [startDate, endDate] = state.dateRange;
        params.period_start = format(startDate, 'yyyy-MM-dd HH:mm');
        params.period_end = format(endDate, 'yyyy-MM-dd HH:mm');
      } else {
        params.period_type = periodType;
      }

      console.log('Sending API request with parameters:', params);

      try {
        const response = await api.get('/metrics', { params });
        console.log('Metrics received from API:', response.data);
        commit('SET_EMPLOYEES_METRICS', { periodType, metrics: response.data });
      } catch (error) {
        console.error('Error loading metrics:', error);
      } finally {
        commit('SET_METRICS_LOADING', false);
      }
    },
    updateDateRange({ commit }, dateRange) {
      commit('setDateRange', dateRange);
    },
    setSelectedDepartments({ commit }, departmentIds) {
      commit('setSelectedDepartments', departmentIds);
    },
  },
  getters: {
    allEmployees(state) {
      let allEmployees = [];
      Object.values(state.employeesByDepartment).forEach(departmentEmployees => {
        allEmployees = allEmployees.concat(departmentEmployees);
      });
      return allEmployees;
    },
    getEmployeesByDepartment: (state) => (departmentId) => {
      departmentId = String(departmentId);
      return state.employeesByDepartment[departmentId] || [];
    },
    getDateRange(state) {
      return state.dateRange;
    },
    getEmployeeMetrics: (state) => (employeeId, periodType) => {
      employeeId = String(employeeId);
      if (state.employeesMetrics[periodType]) {
        return state.employeesMetrics[periodType][employeeId] || {};
      } else {
        return {};
      }
    },
    isLoading: (state) => (employeeId, periodType) => {
      employeeId = String(employeeId);
      return state.loadingStatus[periodType]?.[employeeId] || false;
    },
    getAllEmployeeIds: (state) => {
      let allEmployeeIds = [];
      Object.values(state.employeesByDepartment).forEach(departmentEmployees => {
        departmentEmployees.forEach(employee => {
          if (employee.id) {
            allEmployeeIds.push(String(employee.id));
          }
        });
      });
      return allEmployeeIds;
    },
    getMetricsForPeriodType: (state) => (periodType) => {
      return state.employeesMetrics[periodType] || {};
    },
    getSelectedPeriodType(state) {
      return state.selectedPeriodType;
    },
    allDepartments(state) {
      return state.departments || [];
    },
    selectedDepartments(state) {
      return state.selectedDepartments;
    },
  },
});

export default store;
