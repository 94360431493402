// main.js

import { createApp } from 'vue';
import App from './App.vue';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import '@mdi/font/css/materialdesignicons.css';
import store from './store';
import axios from 'axios';
import echo from './echo';

axios.defaults.baseURL = 'http://127.0.0.1:8000';

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

const app = createApp(App);

app.config.globalProperties.$axios = axios;

window.Echo = echo;
console.log('Echo instance initialized:', window.Echo);

// Подписка на канал 'metrics-updates' и прослушивание события 'MetricsUpdated'
window.Echo.channel('metrics-updates')
  .listen('.MetricsUpdated', (e) => {
    if (e && e.metrics) {
      const periodType = e.metrics.period_type;
      store.commit('UPDATE_METRICS', { periodType, metrics: e.metrics });
    }
  });


app
  .use(vuetify)
  .use(store)
  .mount('#app');
