<!-- AccordionHeader.vue-->
<template>
  <div class="accordion-item">
    <!-- Структура, как в AccordionItem -->
    <div class="accordion_wrap">
      <div class="dealership-info">
        <span class="dealership-name"></span>
      </div>
    </div>

    <!-- Метрики -->
    <div class="metrics">
      <!-- Белый круг -->
      

      <!-- График бублик -->
      <div class="metric">        
          <div>Кол-во обработанных</div>
          <div>Кол-во необработанных</div> 
      </div>

      <!-- Цветной круг -->
      <div class="metric">
        <div>TTFC</div>
        <img src="/icons/ArrowHead.svg" alt="Arrow">
      </div>
      <div class="metric">
        <div>Оценка качества</div>
        <img src="/icons/ArrowHead.svg" alt="Arrow">
      </div>
      <div class="metric">
        <div>Приветствие</div>
        <img src="/icons/ArrowHead.svg" alt="Arrow">        
      </div>
      <div class="metric">
        <div>Выясление потребностей</div>
        <img src="/icons/ArrowHead.svg" alt="Arrow">        
      </div>
      <div class="metric">
        <div>Преимущества автомобиля</div>
        <img src="/icons/ArrowHead.svg" alt="Arrow">        
      </div>
      <div class="metric">
        <div>Способ приобретения</div>
        <img src="/icons/ArrowHead.svg" alt="Arrow">        
      </div>
      <div class="metric">
        <div>Договоренности о визите/контакте</div>
        <img src="/icons/ArrowHead.svg" alt="Arrow">        
      </div>
      <div class="metric">
        <div>Обмен контактами</div>
        <img src="/icons/ArrowHead.svg" alt="Arrow">        
      </div>
      <div class="metric">
        <div>Заинтересованность в клиенте</div>
        <img src="/icons/ArrowHead.svg" alt="Arrow">        
      </div>     
    </div>
    <span data-v-16bc43d4="" class="dropdown-arrow"></span>    
  </div>
</template>

<script>
function adjustHeaderWidth() {
  const headers = document.querySelectorAll('.metric'); // Селекторы для заголовков
  let maxWidth = 0;

  // Определяем максимальную ширину заголовка
  headers.forEach(header => {
    if (header.offsetWidth > maxWidth) {
      maxWidth = header.offsetWidth;
    }
  });

  // Применяем эту ширину ко всем заголовкам
  headers.forEach(header => {
    header.style.width = `${maxWidth}px`;
    header.style.textAlign = 'center'; // Центрирование текста
  });
}

window.onload = adjustHeaderWidth;
window.onresize = adjustHeaderWidth; // Пересчет при изменении размеров окна



</script>

<style scoped>

.accordion-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 25px;
  padding: 0 20px;
}

.accordion_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 340px;
}

.dealership-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 340px;
  gap: 18px;
}

.metrics {
  display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1460px;
    min-width: 890px;
    width: 100%;
}

.metric-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.metric {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
}
.metric img {
  padding-top: 4px;
}
.metric:nth-child(1) {
  flex-direction: column;  
  white-space: nowrap;
  align-items: stretch;
}
.metric > div {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

</style>
