<!-- MetricsModule.vue -->

<template>
  <div class="metric-wrapper">
    <!-- График бублик (Donut Chart) -->
    <div v-if="type === 'donut'">
      <div v-if="!isLoading" class="donut-container">
        <canvas ref="donutChart"></canvas>
        <div class="donut-center-text">{{ total }}</div>
      </div>
      <div v-if="isLoading" class="loading-placeholder">
        <!-- Здесь можно разместить спиннер или плейсхолдер во время загрузки -->
      </div>
    </div>

    <!-- Цветной круг с дискретной сменой цвета (Color Circle) -->
    <div v-else-if="type === 'color-circle'">
      <div class="circle-container">
        <div class="circle-wrapper" :class="{ 'loading': isLoading }">
          <circle-progress
            :size="89"
            :percent="isLoading ? 1 : displayPercent"
            :border-width="8"
            :border-bg-width="8"
            :fill-color="isLoading ? 'rgba(255,255,255,0.9)' : fillColor"
            :empty-color="'#262627'"
            :transition="1000"
            :linecap="'round'"
          />
        </div>
        <div class="circle-label" v-if="!isLoading">{{ displayValue }}</div>
      </div>
    </div>

    <!-- Белый круг (White Circle) -->
    <div v-else-if="type === 'white-circle'">
      <div class="circle-container">
        <div class="circle-wrapper" :class="{ 'loading': isLoading }">
          <circle-progress
            :size="89"
            :percent="isLoading ? 1 : displayPercent"
            :border-width="8"
            :border-bg-width="8"
            :fill-color="isLoading ? 'rgba(255,255,255,0.2)' : fillColor"
            :empty-color="'#262627'"
            :transition="1000"
            :linecap="'round'"
          />
        </div>
        <div class="circle-label" v-if="!isLoading">{{ displayValue }}</div>
      </div>
    </div>
  </div>
</template>


<script>
import CircleProgress from "vue3-circle-progress";
import { Chart, ArcElement, Tooltip, Legend, DoughnutController } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ArcElement, Tooltip, Legend, DoughnutController, ChartDataLabels);

export default {
  components: {
    CircleProgress,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => ['white-circle', 'donut', 'color-circle'].includes(value),
    },
    value: {
      type: Number,
      default: 0,
    },
    processed: {
      type: Number,
      default: 0,
    },
    unprocessed: {
      type: Number,
      default: 0,
    },
    totalProcessedCalls: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      donutChartInstance: null,
    };
  },
  computed: {
    total() {
      return this.processed + this.unprocessed;
    },
    displayPercent() {
      if (this.isLoading) {
        return 0;
      } else if (this.type === "white-circle") {
        // Для белого круга мы отображаем значение как процент от 100
        const value = Number(this.value);
        const percent = Math.min(Math.max((value / 100) * 100, 0), 100);
        return percent;
      } else if (this.type === "color-circle") {
        if (this.totalProcessedCalls > 0) {
          const value = Number(this.value);
          const totalProcessedCalls = Number(this.totalProcessedCalls);
          const factor = totalProcessedCalls / 100;
          const percent = value / factor;
          const displayPercent = Math.round(percent);
          return displayPercent;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    displayValue() {
      if (this.isLoading) {
        return "";
      } else {
        return this.value;
      }
    },
    fillColor() {
      return this.getFillColor(this.displayPercent);
    },
    processedPercent() {
      const total = this.processed + this.unprocessed;
      if (total === 0) return 0;
      const percent = (this.processed / total) * 100;
      return Math.round(percent);
    },
    unprocessedPercent() {
      const total = this.processed + this.unprocessed;
      if (total === 0) return 0;
      const percent = (this.unprocessed / total) * 100;
      return Math.round(percent);
    },
  },
  mounted() {
    if (this.type === 'donut' && !this.isLoading) {
      this.$nextTick(() => {
        this.renderDonutChart();
      });
    }
  },
  updated() {
    if (this.type === 'donut' && !this.isLoading) {
      this.$nextTick(() => {
        this.renderDonutChart();
      });
    }
  },
  methods: {
    getFillColor(percent) {
      let color;
      if (percent <= 20) color = "#ff2819"; // Красный
      else if (percent <= 40) color = "#ff7019"; // Оранжевый
      else if (percent <= 60) color = "#ffb819"; // Желтый
      else color = "#bbda14"; // Зеленый      
      return color;
    },
    renderDonutChart() {
      const ctx = this.$refs.donutChart?.getContext("2d");
      if (!ctx) {
        console.warn("Контекст канваса не найден или отсутствует");
        return;
      }
      if (this.donutChartInstance) {
        this.donutChartInstance.destroy();
      }
      try {
        this.donutChartInstance = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: ['Обработанные', 'Необработанные'],
            datasets: [{
              data: [this.processedPercent, this.unprocessedPercent],
              backgroundColor: ['#bbda14', '#ff2819'],
              borderWidth: 0,
            }],
          },
          options: {
            cutout: '75%',
            responsive: true,
            maintainAspectRatio: true,
            layout: {
              padding: 0,
            },
            plugins: {
              legend: { display: false },
              tooltip: { enabled: false },
              datalabels: {
                color: '#ffffff',
                font: {
                  weight: '100',
                  size: 10,
                },
                formatter: (value) => {
                  return value;
                },
                anchor: 'end',
                align: 'start',
                offset: 5,
              },
            },
            animations: false,
          },
        });
      } catch (error) {
        console.error('Ошибка при инициализации донат-графика:', error);
      }
    },
    updateDonutChart() {
      if (this.donutChartInstance) {
        try {
          const processedPercent = isNaN(this.processedPercent) ? 0 : this.processedPercent;
          const unprocessedPercent = isNaN(this.unprocessedPercent) ? 0 : this.unprocessedPercent;

          this.donutChartInstance.data.datasets[0].data = [processedPercent, unprocessedPercent];
          this.donutChartInstance.update();
        } catch (error) {
          console.error('Ошибка при обновлении донат-графика:', error);
        }
      } else {
        this.renderDonutChart();
      }
    },
  },
  watch: {
    isLoading(newVal) {
      if (!newVal && this.type === 'donut') {
        this.$nextTick(() => {
          this.renderDonutChart();
        });
      }
    },
    processed() {
      if (this.type === 'donut' && !this.isLoading) {
        this.updateDonutChart();
      }
    },
    unprocessed() {
      if (this.type === 'donut' && !this.isLoading) {
        this.updateDonutChart();
      }
    },
  },
};
</script>

<style scoped>
.metric-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.donut-container {
  position: relative;
  width: 89px;
  height: 89px;
  display: flex;
  align-items: center;
  justify-content: center;
}

canvas {
  width: 89px;
  height: 89px;
  display: block;
}

.donut-center-text {
  position: absolute;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}

/* Стили для цветного круга */
.circle-label {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 2px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-placeholder {
  width: 89px;
  height: 89px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Добавляем контейнер для круга */
.circle-container {
  position: relative;
  width: 89px;
  height: 89px;
}

/* Обёртка для круга */
.circle-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.circle-wrapper.loading ::v-deep svg {
  animation: rotate360 1s linear infinite;
  transform-origin: center center;
}
</style>
