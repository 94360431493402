<!--DateRangeFilter.vue-->
<template>
  <div class="date-range">
    <ul class="dropdown">
      <li
        v-for="option in dateOptions"
        :key="option"
        :class="{ active: selectedOption === option }"
        @click="selectOption(option)"
      >
        {{ option }}
      </li>
      <li :class="{ active: selectedOption === 'Период' }">
        <button class="dropbtn" @click="toggleDatePicker">
          {{ selectedCustomRange || 'Период' }}
        </button>
      </li>
    </ul>

    <!-- Компонент выбора диапазона дат -->
    <div v-if="showDatePicker" class="date-picker-popup">
      <DatePicker
        v-model="dateRange"
        :range="true"
        locale="ru"
        :translations="datePickerTranslations"
        :pattern="datePickerPatterns"
        :inline="true"
        :multi-calendars="2"
        :enable-time-picker="true"
        :close-on-select="false"
        :format="dateFormat"
        :week-start="1"
        :timezone="'Etc/GMT-5'"
        :confirm="true"
        modelType="Date"
        @confirm="applyCustomRange"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex'; // Импортируем Vuex
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export default {
  name: 'DateRangeFilter',
  components: {
    DatePicker,
  },
  setup(props, { emit }) {
  const store = useStore(); // Используем store Vuex
  const dateOptions = ['Сегодня', 'Вчера', 'Неделя', 'Месяц', 'Квартал'];
  const selectedOption = ref('Сегодня');
  const showDatePicker = ref(false);

  // Получаем и связываем dateRange с состоянием Vuex
  const dateRange = computed({
    get() {
      return store.getters.getDateRange || []; // Получаем значение из Vuex
    },
    set(value) {
      store.dispatch('updateDateRange', value); // Обновляем состояние в Vuex
    }
  });

  const selectedCustomRange = ref('');

  const dateFormat = 'dd MMM yyyy HH:mm';
  const ruLocale = ru;

  const datePickerTranslations = {
    selectDate: 'Выберите дату',
    selectTime: 'Выберите время',
    selectDateTime: 'Выберите дату и время',
    today: 'Сегодня',
    now: 'Сейчас',
    clear: 'Очистить',
    cancel: 'Отмена',
    confirm: 'Применить',
    week: 'Неделя',
    month: 'Месяц',
    year: 'Год',
    monthBeforeYear: true,
    previousMonth: 'Предыдущий месяц',
    nextMonth: 'Следующий месяц',
    previousYear: 'Предыдущий год',
    nextYear: 'Следующий год',
  };

  const datePickerPatterns = {
    date: 'dd MMM yyyy',
    month: 'MMMM',
    year: 'yyyy',
    time: 'HH:mm',
    datetime: 'dd MMM yyyy HH:mm',
    monthShort: 'MMM',
    dayShort: 'eee',
    day: 'eeee',
  };

  const now = new Date();
  selectedCustomRange.value = format(now, 'dd MMM', { locale: ruLocale });

  watch(dateRange, (newVal) => {
    console.log('dateRange обновлен через Vuex:', newVal);
  });

  const selectOption = (option) => {
    console.log('Опция выбрана:', option);
    selectedOption.value = option;
    showDatePicker.value = false;

    const now = new Date();
    let startDate;
    let periodType; // Добавляем переменную для хранения типа периода

    switch (option) {
      case 'Сегодня':
        startDate = new Date(now);
        startDate.setHours(0, 0, 0, 0);
        now.setHours(23, 59, 59, 999);
        selectedCustomRange.value = format(now, 'dd MMM', { locale: ruLocale });
        periodType = 'day'; // Изменено с 'today' на 'day'
        break;
      case 'Вчера':
        startDate = new Date(now);
        startDate.setDate(startDate.getDate() - 1);
        startDate.setHours(0, 0, 0, 0);
        now.setDate(now.getDate() - 1);
        now.setHours(23, 59, 59, 999);
        selectedCustomRange.value = format(startDate, 'dd MMM', { locale: ruLocale });
        periodType = 'yesterday';
        break;
      case 'Неделя':
        startDate = new Date(now);
        startDate.setDate(startDate.getDate() - 7);
        startDate.setHours(0, 0, 0, 0);
        now.setHours(23, 59, 59, 999);
        selectedCustomRange.value = `${format(startDate, 'dd MMM', { locale: ruLocale })} - ${format(now, 'dd MMM', { locale: ruLocale })}`;
        periodType = 'week';
        break;
      case 'Месяц':
        startDate = new Date(now);
        startDate.setMonth(startDate.getMonth() - 1);
        startDate.setHours(0, 0, 0, 0);
        now.setHours(23, 59, 59, 999);
        selectedCustomRange.value = `${format(startDate, 'dd MMM', { locale: ruLocale })} - ${format(now, 'dd MMM', { locale: ruLocale })}`;
        periodType = 'month';
        break;
      case 'Квартал': {
        const currentMonth = now.getMonth();
        const startMonth = currentMonth - (currentMonth % 3);
        startDate = new Date(now.getFullYear(), startMonth, 1);
        startDate.setHours(0, 0, 0, 0);
        now.setHours(23, 59, 59, 999);
        selectedCustomRange.value = `${format(startDate, 'dd MMM', { locale: ruLocale })} - ${format(now, 'dd MMM', { locale: ruLocale })}`;
        periodType = 'quarter';
        break;
      }
      default:
        startDate = new Date(now);
        periodType = 'day';
        break;
    }


    // Обновляем диапазон дат через Vuex
    dateRange.value = [startDate, now];

    // Устанавливаем выбранный период в Vuex
    store.commit('setSelectedPeriodType', periodType);

    console.log('Диапазон дат через Vuex:', dateRange.value);

    emit('date-range-selected', {
      startDate: format(startDate, 'yyyy-MM-dd HH:mm'),
      endDate: format(now, 'yyyy-MM-dd HH:mm'),
    });
  };

  const toggleDatePicker = () => {
    showDatePicker.value = !showDatePicker.value;
  };

  const applyCustomRange = () => {
    console.log('Применение пользовательского диапазона');
    if (
      dateRange.value &&
      dateRange.value.length === 2 &&
      dateRange.value[0] instanceof Date &&
      dateRange.value[1] instanceof Date
    ) {
      const [startDateObj, endDateObj] = dateRange.value;
      const formattedStartDate = format(startDateObj, 'dd MMM', { locale: ruLocale });
      const formattedEndDate = format(endDateObj, 'dd MMM', { locale: ruLocale });
      selectedCustomRange.value = `${formattedStartDate} - ${formattedEndDate}`;

      selectedOption.value = 'Период';
      showDatePicker.value = false;

      // Устанавливаем выбранный период в Vuex
      store.commit('setSelectedPeriodType', 'custom');
      // Обновляем dateRange в Vuex
      dateRange.value = [startDateObj, endDateObj];

      emit('date-range-selected', {
        startDate: format(startDateObj, 'yyyy-MM-dd HH:mm'),
        endDate: format(endDateObj, 'yyyy-MM-dd HH:mm'),
      });
    } else {
      alert('Пожалуйста, выберите начальную и конечную даты');
    }
  };

  return {
    dateOptions,
    selectedOption,
    showDatePicker,
    dateRange, // Теперь диапазон дат связан с Vuex
    selectedCustomRange,
    selectOption,
    toggleDatePicker,
    applyCustomRange,
    datePickerTranslations,
    datePickerPatterns,
    dateFormat,
    ruLocale,
  };
}};
</script>



  <style scoped>
  /* Ваши стили остаются без изменений */
  .date-range {
    max-width: 667px;
    width: 100%;
    position: relative;
  }
  
  .date-range .dropdown {
    padding: 0;
    margin: 0;
    background: #262627;
    border-radius: 25px;
    border: 1px solid #454547;
    display: flex;
  }
  
  .date-range .dropdown li {
    width: 110px;
    list-style: none;
    font-size: 14px;
    font-weight: 300;
    padding: 0;
    cursor: pointer;
    transition: 0.4s;
    text-align: center;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .date-range .dropdown li.active {
    background: #9C9C9C;
    user-select: none;
  }
  
  .date-range .dropdown li:last-child {
    min-width: 160px;    
  }
  
  .date-range .dropdown li button {
    background: transparent;
    border: none;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    font-weight: 300;
    color: inherit;
    white-space: nowrap;
    pointer-events: none;
  }
  
  .date-range .dropdown li button::after {
    content: '';
    display: inline-block;
    width: 13px;
    height: 8px;
    background-image: url('../../data/src/arrow_button.svg');
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 8px;
  }
  
  .date-picker-popup {
    position: absolute;
    top: 100%;
    right: 0;
    background: #262627;
    padding: 15px;
    border: 1px solid #454547;
    border-radius: 15px;
    z-index: 100;
    width: max-content;
  }
  
  .apply-button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #9C9C9C;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
  }
  
  /* Скрываем кнопку "Select" */
/*   :deep(.dp__action_buttons) {
    display: none;
  } */
  
  /* Дополнительные стили для компонента */
  :deep(.dp__menu) {
    background-color: #262627;
    color: #fff;
  }
  
  :deep(.dp__header),
  :deep(.dp__weekdays),
  :deep(.dp__calendar) {
    color: #fff;
  }
  
  :deep(.dp__cell.dp__active_date),
  :deep(.dp__cell_in_range) {
    background-color: #9C9C9C;
    color: #000;
  }
  
  :deep(.dp__cell:hover) {
    background-color: #454547;
  }
  </style>
  