<template>
  <header class="header-container">
    <nav>
      <div class="logo">
        <img :src="logoSrc" alt="Allur">
      </div>
      <div class="filters">
        <div class="pseudo-input search" contenteditable="false" id="searchInput" disabled>Поиск...</div>
        <div class="header_wrapper">
          <DateRangeFilter @date-range-selected="onDateRangeSelected" />
          <div class="headerWrap">
            <DepartmentSelect />
            <div class="employee-select" @click="showPopup">
              <span>Сотрудники</span>
              <ul v-if="isPopupVisible" class="dropdown">
                <li>В разработке</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import logo from '../../data/src/logo.svg';
import DateRangeFilter from './DateRangeFilter.vue';
import DepartmentSelect from './DepartmentSelect.vue';

export default {
  name: 'AppHeader',
  components: {
    DateRangeFilter,
    DepartmentSelect,
  },
  data() {
    return {
      logoSrc: logo,
      isPopupVisible: false,
    };
  },
  methods: {
    onDateRangeSelected({ startDate, endDate }) {
      console.log('Выбран диапазон дат:', startDate, endDate);
    },
    showPopup() {
      this.isPopupVisible = !this.isPopupVisible;
    },
  },
};
</script>

<style scoped>
.header-container {
  position: sticky;
  padding-top: 16px;
  z-index: 100;
  background: #262627;
  top: 0;
  display: flex;
  justify-content: flex-end;
}
.header-container nav {
  width: 100%;
  max-width: 1848px;
  margin: 0 1.15%;
  height: 85px;
  border-radius: 25px;
  background-color: #323234;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  margin: 18px 0;
  margin-left: 18px;
}

.filters, ul.dropdown {
  display: flex;
  align-items: center;
  max-height: 50px !important;
  height: 50px !important;
}

.pseudo-input {
  max-width: 240px;
  min-width: 200px;
  width: 100%;
  margin-left: 53px;
  border: 1px solid #454547;
  border-radius: 25px;
  padding: 15px 0 12px 21px;
  color: #9C9C9C;
  background: #262627;
  font-size: 14px;
  pointer-events: none;
}
.header_wrapper {
  display: flex;
  gap: 62px;
  height: 50px;
}
.filters {
  width: 100%;
  justify-content: space-between;
}
.headerWrap {
  display: flex;
  margin-right: 20px;
  gap: 10px;
}

.dealership-select, .employee-select {
  display: flex;
  padding: 12px 18px;
  width: 220px;
  margin: 0;
  background: #262627;
  border-radius: 25px;
  border: 1px solid #454547;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dealership-select::after, .employee-select::after {
  content: '';
  display: inline-block;
  width: 13px;
  height: 8px;
  background-image: url('../../data/src/arrow_button.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 8px;
  vertical-align: middle;
}

ul.dropdown {
  background: #333;
  border-radius: 8px;
  padding: 10px;
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 200px;   
}

ul.dropdown li {
  color: #fff;
  padding: 10px;  
}
</style>
