<!--EmployeeCard.vue-->
<template>
  <div class="employee-card">
    <!-- Информация о сотруднике -->
    <div class="employee-info">
      <div class="card_wrapper">
        <img
          :src="employee.photo || defaultPhoto"
          @error="onError"
          alt="Фото сотрудника"
        >
        <div class="employee-details">
          <span class="employee-name">{{ employee.name }}</span>
          <span class="employee-position">{{ employee.position }}</span>
        </div>
      </div>
      <span class="employee-tag" v-if="employee.tag">{{ employee.tag }}</span>
    </div>    

    <!-- Метрики сотрудника -->
    <div class="metrics">
      <div
        class="metric"
        v-for="(metric, index) in metricsList"
        :key="index"
      >
        <MetricsModule 
        :type="metric.type"
        :value="metric.value"
        :processed="metric.processed"
        :unprocessed="metric.unprocessed"
        :totalProcessedCalls="metric.totalProcessedCalls"
        :is-loading="isLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MetricsModule from './MetricsModule.vue';

export default {
  components: {
    MetricsModule,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getEmployeeMetrics: 'getEmployeeMetrics',
      storeIsLoading: 'isLoading', // Алиас для isLoading из Vuex
    }),
    // Метрики сотрудника
    employeeMetrics() {
      const periodType = this.$store.getters.getSelectedPeriodType;
      return this.getEmployeeMetrics(this.employee.id, periodType) || {};
    },
    isLoading() {
      const periodType = this.$store.getters.getSelectedPeriodType;
      return this.$store.getters.isLoading(this.employee.id, periodType);
    }, 
    metricsList() {
      const totalProcessedCalls = this.employeeMetrics.processed_calls || 0;

      return [
        {
          processed: this.employeeMetrics.processed_calls || 0,
          unprocessed: this.employeeMetrics.unprocessed_calls || 0,
          type: 'donut',
          isLoading: this.isLoading,
        },
        {
          value: this.employeeMetrics.ttfc || 0,
          type: 'color-circle',
          totalProcessedCalls: totalProcessedCalls,
          isLoading: this.isLoading,
        },
        {
          value: this.employeeMetrics.quality_assessment || 0,
          type: 'color-circle',
          totalProcessedCalls: totalProcessedCalls,
          isLoading: this.isLoading,
        },
        {
          value: this.employeeMetrics.greeting || 0,
          type: 'color-circle',
          totalProcessedCalls: totalProcessedCalls,
          isLoading: this.isLoading,
        },
        {
          value: this.employeeMetrics.needs_identification || 0,
          type: 'color-circle',
          totalProcessedCalls: totalProcessedCalls,
          isLoading: this.isLoading,
        },
        {
          value: this.employeeMetrics.car_advantages || 0,
          type: 'color-circle',
          totalProcessedCalls: totalProcessedCalls,
          isLoading: this.isLoading,
        },
        {
          value: this.employeeMetrics.acquisition_method || 0,
          type: 'color-circle',
          totalProcessedCalls: totalProcessedCalls,
          isLoading: this.isLoading,
        },
        {
          value: this.employeeMetrics.agreement_on_contact || 0,
          type: 'color-circle',
          totalProcessedCalls: totalProcessedCalls,
          isLoading: this.isLoading,
        },
        {
          value: this.employeeMetrics.contact_exchange || 0,
          type: 'color-circle',
          totalProcessedCalls: totalProcessedCalls,
          isLoading: this.isLoading,
        },
        {
          value: this.employeeMetrics.customer_interest || 0,
          type: 'color-circle',
          totalProcessedCalls: totalProcessedCalls,
          isLoading: this.isLoading,
        },
        // Дополнительные метрики могут быть добавлены здесь
      ];
    },   
  },
  methods: {  
    onError(event) {
      // Если произошла ошибка загрузки изображения, устанавливаем заглушку
      event.target.src = this.defaultPhoto;
    },
  },
  data() {
    return {
      defaultPhoto: 'http://127.0.0.1:8000/user_photo/user_40083_circle.png', // Путь к заглушке по умолчанию
    };
  },
};
</script>

<style scoped>
.employee-list-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.employee-details {
  display: flex;
  flex-direction: column;
}
.employee-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.employee-card {
  display: flex;
  align-items: center; 
  border-radius: 12px;
}

.employee-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.employee-info {
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 380px;
  height: 125px;
  background-color: #2c2c2d;
  border-radius: 18px;
  border: 4px solid #323234;
  padding: 18px;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
}
.card_wrapper {
  display: flex;
  width: 100%;
  gap: 18px;
}
.card_wrapper > img {
  width: 51px;
  height: 51px;
  border-radius: 50%;
}
.employee-name {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
.employee-position {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9C9C9C;
}
.employee-tag {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #FFFFFF;
  padding: 4.5px 8px;
  border-radius: 4px;
  background: #323234;
}

.metrics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1371px;
  min-width: 890px;
  width: 100%;
}
.metric {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 0;
  background-color: #2c2c2d;
}
.employee-card > .metrics > .metric {
  border-right: 4px solid #323234;
  border-left: 4px solid #323234;  
}
.employee-card:nth-child(1) > .metrics > .metric {
  border: 4px solid #323234;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom: none;
}
.employee-card:nth-last-child(1) > .metrics > .metric {
  border: 4px solid #323234;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  border-top: none;
}
</style>
