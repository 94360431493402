<!-- App.vue -->
<template>
  <head>
    <template v-for="employee in allEmployees" :key="employee.id">
      <link v-if="employee.photo" rel="preload" :href="employee.photo" as="image">
    </template>
  </head>
  <div id="app-root">    
    <AppHeader />
    <section class="dealerships-list">
      <div class="accordion-container">
        <!-- Компонент заголовка -->
        <AccordionHeader />

        <!-- Список аккордеонов с фильтрацией -->
        <AccordionItem
          v-for="department in filteredDepartments"
          :key="department.id"
          :itemData="department"
        />
      </div>
    </section>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AccordionHeader from './components/Accordion/AccordionHeader.vue';
import AccordionItem from './components/Accordion/AccordionItem.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {    
    AppHeader,
    AccordionHeader,
    AccordionItem,    
  },
  mounted() {    
    console.log('Монтирование App.vue, вызываем fetchDepartmentsWithEmployees');
    this.initializeData();
  },
  computed: {
    ...mapGetters(['allEmployees', 'getSelectedPeriodType', 'getAllEmployeeIds', 'allDepartments', 'selectedDepartments']),
    // Фильтруем департаменты на основе выбранных
    filteredDepartments() {
      if (this.selectedDepartments.length === 0) {
        return this.allDepartments;
      }
      return this.allDepartments.filter(department => 
        this.selectedDepartments.includes(String(department.id))
      );
    },
  },
  methods: {
    initializeData() {
      this.$store.dispatch('fetchDepartmentsWithEmployees').then(() => {
        this.preloadEmployeePhotos();
        this.loadMetrics();
      }).catch(error => {
        console.error('Ошибка при инициализации данных:', error);
      });
    },
    preloadEmployeePhotos() {
      const employees = this.allEmployees;
      employees.forEach(employee => {
        const img = new Image();
        if (employee.photo) {
          img.src = employee.photo;
        }
      });
    },
    loadMetrics() {
      const employeeIds = this.getAllEmployeeIds;
      // Загружаем метрики за сегодня
      this.$store.dispatch('fetchMetrics', { employeeIds, periodType: 'day' });
      // Фоновая загрузка остальных периодов
      ['yesterday', 'week', 'month', 'quarter'].forEach(periodType => {
        this.$store.dispatch('fetchMetrics', { employeeIds, periodType });
      });
    },
  },   
  watch: {
    getSelectedPeriodType(newVal) {
      const metrics = this.$store.getters.getMetricsForPeriodType(newVal);
      if (!metrics || Object.keys(metrics).length === 0) {
        this.$store.dispatch('fetchMetrics', { employeeIds: this.getAllEmployeeIds, periodType: newVal });
      }
    },
  },
};
</script>

<style>
/* Глобальные стили */
@import '../data/style/normalize.min.css';
@import '../data/style/global.css';

#app-root {
  position: relative;
}
/* Добавьте дополнительные стили для компонента */
.dealerships-list {
  padding: 20px;
  z-index: 90;
}
#app {
  height: 100vh; /* Установим приложение на всю высоту экрана */
  overflow-y: auto; /* Добавляем скроллирование только для содержимого приложения */
}

.accordion-container {
  margin-top: 20px;
  position: relative;
}
.accordion-item:nth-child(1) {
  position: sticky;
  top: 100px;
  z-index: 10;
  background: #262627;
}
</style>
