<!--AccordionItem.vue-->
<template>
  <div class="accordion-item" :class="{ 'no-hover': isExpanded }" @click="toggleExpand">
    <div class="accordionWrap">
      <div class="accordion_wrap">
        <div class="dealership-info">
          <MetricsModule 
              :value="departmentMetrics.overallMetric" 
              type="white-circle" 
              :isLoading="isLoading"
          />
          <span class="dealership-name">{{ itemData.name }}</span>
        </div>
      </div>

      <!-- Секция метрик -->
      <div class="metrics">
        <div class="metric">
          <MetricsModule 
          :processed="departmentMetrics.processed_calls" 
          :unprocessed="departmentMetrics.unprocessed_calls" 
          type="donut" 
          :isLoading="isLoading"            
          />        
        </div>

        <div class="metric">
          <MetricsModule 
            :value="departmentMetrics.ttfc" 
            :type="'color-circle'"
            :totalProcessedCalls="departmentMetrics.processed_calls"
            :isLoading="isLoading"
          />
        </div>
        <div class="metric">
          <MetricsModule 
            :value="departmentMetrics.quality_assessment" 
            :type="'color-circle'"
            :totalProcessedCalls="departmentMetrics.processed_calls"
            :isLoading="isLoading"
          />
        </div>
        <div class="metric">
          <MetricsModule 
            :value="departmentMetrics.greeting" 
            :type="'color-circle'"
            :totalProcessedCalls="departmentMetrics.processed_calls"
            :isLoading="isLoading"
          />
        </div>
        <div class="metric">
          <MetricsModule 
            :value="departmentMetrics.needs_identification" 
            :type="'color-circle'"
            :totalProcessedCalls="departmentMetrics.processed_calls"
            :isLoading="isLoading"
          />
        </div>
        <div class="metric">
          <MetricsModule 
            :value="departmentMetrics.car_advantages" 
            :type="'color-circle'"
            :totalProcessedCalls="departmentMetrics.processed_calls"
            :isLoading="isLoading"
          />
        </div>
        <div class="metric">
          <MetricsModule 
            :value="departmentMetrics.acquisition_method" 
            :type="'color-circle'"
            :totalProcessedCalls="departmentMetrics.processed_calls"
            :isLoading="isLoading"
          />
        </div>
        <div class="metric">
          <MetricsModule 
            :value="departmentMetrics.agreement_on_contact" 
            :type="'color-circle'"
            :totalProcessedCalls="departmentMetrics.processed_calls"
            :isLoading="isLoading"
          />
        </div>
        <div class="metric">
          <MetricsModule 
            :value="departmentMetrics.contact_exchange" 
            :type="'color-circle'"
            :totalProcessedCalls="departmentMetrics.processed_calls"
            :isLoading="isLoading"
          />
        </div>
        <div class="metric">
          <MetricsModule 
            :value="departmentMetrics.customer_interest" 
            :type="'color-circle'"
            :totalProcessedCalls="departmentMetrics.processed_calls"
            :isLoading="isLoading"
          />
        </div>
      </div>

      <span class="dropdown-arrow" :class="{ 'open': isExpanded }"></span>
      
    </div>

    <div v-show="isExpanded" class="card_line"></div>

    <!-- Список сотрудников с использованием компонента EmployeeCard -->
    <div v-show="isExpanded" class="employee-list-container">
      <div class="employee-list">
        <EmployeeCard
          v-for="(employee) in employees"
          :key="employee.id"
          :employee="employee"
        />
      </div>
    </div>

    <div v-show="isExpanded" class="more-details-container">
      <button class="more-details-button" @click.stop="showMoreDetails">Подробнее</button>
    </div>
  </div>
</template>

<script>
import MetricsModule from './MetricsModule.vue';
import EmployeeCard from './EmployeeCard.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    MetricsModule,
    EmployeeCard,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters({
      getEmployeeMetrics: 'getEmployeeMetrics',
      getSelectedPeriodType: 'getSelectedPeriodType',
    }),
    employees() {
      return this.$store.getters.getEmployeesByDepartment(this.itemData.id);
    },
    departmentMetrics() {
    const periodType = this.$store.getters.getSelectedPeriodType;
    const metricsSum = {
      processed_calls: 0,
      unprocessed_calls: 0,
      ttfc: 0,
      quality_assessment: 0,
      greeting: 0,
      needs_identification: 0,
      car_advantages: 0,
      acquisition_method: 0,
      agreement_on_contact: 0,
      contact_exchange: 0,
      customer_interest: 0,
    };

    let employeeCount = 0;

    this.employees.forEach(employee => {
      const metrics = this.getEmployeeMetrics(employee.id, periodType);
      if (metrics && Object.keys(metrics).length > 0) {
        metricsSum.processed_calls += metrics.processed_calls || 0;
        metricsSum.unprocessed_calls += metrics.unprocessed_calls || 0;
        metricsSum.ttfc += metrics.ttfc || 0;
        metricsSum.quality_assessment += metrics.quality_assessment || 0;
        metricsSum.greeting += metrics.greeting || 0;
        metricsSum.needs_identification += metrics.needs_identification || 0;
        metricsSum.car_advantages += metrics.car_advantages || 0;
        metricsSum.acquisition_method += metrics.acquisition_method || 0;
        metricsSum.agreement_on_contact += metrics.agreement_on_contact || 0;
        metricsSum.contact_exchange += metrics.contact_exchange || 0;
        metricsSum.customer_interest += metrics.customer_interest || 0;
        employeeCount += 1;
      }
    });

    if (employeeCount > 0) {
      const totalCalls = metricsSum.processed_calls + metricsSum.unprocessed_calls;

      const metricsAverages = [
        metricsSum.processed_calls / employeeCount,
        metricsSum.ttfc / employeeCount,
        metricsSum.quality_assessment / employeeCount,
        metricsSum.greeting / employeeCount,
        metricsSum.needs_identification / employeeCount,
        metricsSum.car_advantages / employeeCount,
        metricsSum.acquisition_method / employeeCount,
        metricsSum.agreement_on_contact / employeeCount,
        metricsSum.contact_exchange / employeeCount,
        metricsSum.customer_interest / employeeCount,
      ];

      const overallMetric = Math.round(
        metricsAverages.reduce((sum, value) => sum + value, 0) / metricsAverages.length
      );

      return {
        total_calls: totalCalls,
        processed_calls: metricsSum.processed_calls,
        unprocessed_calls: metricsSum.unprocessed_calls,
        ttfc: Math.round(metricsSum.ttfc / employeeCount),
        quality_assessment: Math.round(metricsSum.quality_assessment / employeeCount),
        greeting: Math.round(metricsSum.greeting / employeeCount),
        needs_identification: Math.round(metricsSum.needs_identification / employeeCount),
        car_advantages: Math.round(metricsSum.car_advantages / employeeCount),
        acquisition_method: Math.round(metricsSum.acquisition_method / employeeCount),
        agreement_on_contact: Math.round(metricsSum.agreement_on_contact / employeeCount),
        contact_exchange: Math.round(metricsSum.contact_exchange / employeeCount),
        customer_interest: Math.round(metricsSum.customer_interest / employeeCount),
        overallMetric: overallMetric,
      };
    } else {
      return {
        total_calls: 0,
        processed_calls: 0,
        unprocessed_calls: 0,
        ttfc: 0,
        quality_assessment: 0,
        greeting: 0,
        needs_identification: 0,
        car_advantages: 0,
        acquisition_method: 0,
        agreement_on_contact: 0,
        contact_exchange: 0,
        customer_interest: 0,
        overallMetric: 0,
      };
    }
  },
    /* employeeMetrics() {
      const periodType = this.$store.getters.getSelectedPeriodType;
      return this.getEmployeeMetrics(this.employee.id, periodType) || {};
    }, */
    isLoading() {
      const periodType = this.getSelectedPeriodType;
      return this.employees.some(employee => {
        return this.$store.getters.isLoading(employee.id, periodType);
      });
    },
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;      
    },    
    showMoreDetails(event) {
      event.stopPropagation();
    },    
  },
};
</script>

<style scoped>
.accordion-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 8px;
  padding: 17px 18px;
  border-radius: 18px;
  border: 1px solid #323234;
  background: #323234;
  transition: 0.4s;
  flex-direction: column;
  gap: 18px;
}

.accordion-item:hover {
  background: #474749;
}

.accordionWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.accordion_wrap {
  display: flex;   
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  min-width: 340px;
}

.dealership-info {  
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 340px;
  gap: 18px;
}
.dealership-info > .metric {
  width: auto !important;
}
.metrics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1371px;
  min-width: 890px;
  width: 100%;  
}

.metric {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
}

.employee-list-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.employee-list {
  width: 100%;
}

.dropdown-arrow {
  height: 12px;
  width: 20px;
  margin-left: 12px;
}

.more-details-container {
  display: flex;
    justify-content: flex-end;    
    width: 100%;
    max-width: 1821px;
    padding-right: 66px;
}

.more-details-button {
  background: #ff2819;
  border-radius: 37px;
  font-size: 18px;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  transition: background 0.3s;
}
.more-details-button:hover {
  background: #e02018;
}
.accordion-item.no-hover {
  border: 1px solid;

}
.accordion-item.no-hover:hover {
  background: #323234;
}
.card_line {
  border-top: 1px solid #9c9c9c;
  width: 100%;
  max-width: 1841px;
}
</style>