<!-- DepartmentSelect.vue -->
<template>
  <div :class="{ 'overlay': isDropdownOpen }">
    <div class="dealership-select-wrapper">
      <div class="dealership-select" @click="toggleDropdown">
        <span class="selected-department-text">
          {{ displaySelectedDepartments }}
        </span>
        <span class="dropdown-arrow" :class="{ 'open': isDropdownOpen }"></span>
      </div>
      <div v-if="isDropdownOpen" class="dropdown-container">
        <div class="dropdown-grid">
          <div
            v-for="department in allDepartments"
            :key="department.id"
            class="dropdown-item"
          >
            <label class="custom-checkbox">
              <input
                type="checkbox"
                :value="department.id"
                :checked="localSelectedDepartments.includes(String(department.id))"
                @change="toggleSelection(department.id)"
              />
              <span class="checkmark"></span>
              {{ department.name }}
            </label>
          </div>
        </div>
        <div class="dropdown-footer">
          <div class="line"></div>
          <button class="apply-button" @click="applySelection">Применить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      isDropdownOpen: false,
      localSelectedDepartments: [],
    };
  },
  computed: {
    ...mapGetters(['allDepartments', 'selectedDepartments']),
    displaySelectedDepartments() {
      if (!this.localSelectedDepartments || !Array.isArray(this.localSelectedDepartments)) {
        return 'Дилерский центр';
      }
      if (this.localSelectedDepartments.length === 0) {
        return 'Дилерский центр';
      } else if (this.localSelectedDepartments.length === 1) {
        const selectedDepartment = this.allDepartments.find(
          dep => String(dep.id) === String(this.localSelectedDepartments[0])
        );
        return selectedDepartment ? selectedDepartment.name : 'Дилерский центр';
      } else {
        const selectedNames = this.localSelectedDepartments
          .map(depId => {
            const department = this.allDepartments.find(dep => String(dep.id) === String(depId));
            return department ? department.name : null;
          })
          .filter(Boolean);
        return selectedNames.length > 0
          ? selectedNames.join(', ').slice(0, 160) + '...'
          : 'Дилерский центр';
      }
    },
  },
  methods: {
    ...mapActions(['setSelectedDepartments', 'fetchDepartmentsWithEmployees']),
    toggleSelection(departmentId) {
      departmentId = String(departmentId);
      const index = this.localSelectedDepartments.indexOf(departmentId);
      if (index > -1) {
        this.localSelectedDepartments.splice(index, 1);
      } else {
        this.localSelectedDepartments.push(departmentId);
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      document.body.style.overflow = this.isDropdownOpen ? 'hidden' : 'auto';
    },
    applySelection() {
      this.setSelectedDepartments(this.localSelectedDepartments); // Вызываем экшен
      this.isDropdownOpen = false;
      document.body.style.overflow = 'auto';
    },
  },
  mounted() {
    // Загружаем департаменты и сотрудников при монтировании компонента
    this.fetchDepartmentsWithEmployees().then(() => {
      // Инициализируем localSelectedDepartments после загрузки департаментов
      this.localSelectedDepartments = [...this.selectedDepartments];
    });
  },
  watch: {
    selectedDepartments(newVal) {
      this.localSelectedDepartments = [...newVal];
    },
  },
};
</script>






<style scoped>
.dealership-select-wrapper {
  position: relative;
}

.dealership-select {
  display: flex;
  padding: 12px 18px;
  width: 220px;
  background: #262627;
  border-radius: 25px;
  border: 1px solid #454547;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.selected-department-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.dropdown-arrow {
  width: 13px;
  height: 8px;
  background-image: url('http://localhost:8080/img/arrow_button.fcfb0ba7.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown-container {
  position: absolute;
  top: 100%;
  right: 0;
  background: #333;
  padding: 37px 35px;
  border: 1px solid #9c9c9c;
  border-radius: 8px;
  z-index: 1000;
  width: 1200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.dropdown-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
}

.custom-checkbox {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #fff;
  border-radius: 3px;
  background-color: transparent;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #fff;
  border: 2px solid #fff;
}

.dropdown-footer {
  margin-top: 20px;
  text-align: right;
}

.apply-button {
  background: #ff2819;
  border-radius: 37px;
  font-size: 18px;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  transition: background 0.3s;
}

.apply-button:hover {
  background: #CC0E00;
}

.line {
  border: 1px solid #9c9c9c;
  margin: 35px 0 19px 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 999;
}
</style>